import React from 'react';
import Header from '../../components/Header/Header';
import useTranslate from '../../hooks/useTranslate';
import Footer from '../../components/Footer/Footer';
import makeApiRequest from '../../utils/makeApiRequest';
import { FaSpinner } from 'react-icons/fa';
import { emailRegex } from '../../constants/emailRegex';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setErrorDetails } from '../../redux/actions';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import check from '../../assets/images/check.png';

const LoginWithEmailAndAccessLink = () => {
    const [email, setEmail] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccessData] = React.useState({ status: false, msg: '' });
    const t = useTranslate();
    const dispatch = useDispatch();
    const { product } = useGetPageConfig();

    const onClickHandler = async () => {
        setLoading(true);
        if (!email) {
            setErrorMessage(() => 'email_id_required');
            setLoading(false);
            return;
        } else if (!emailRegex.test(email)) {
            setErrorMessage(() => 'invalid_email_address');
            setLoading(false);
            return;
        }
        try {
            const response = await makeApiRequest({
                url: 'https://cc.tallymans.com/api/v1/portal/login',
                method: 'POST',
                body: { email: email },
            });
            if (!response.success) {
                setErrorMessage(() => 'server_error');
                dispatch(setErrorDetails('access-error: ' + 'server_error'));
                setLoading(false);
                return;
            }
            setLoading(false);
            setErrorMessage('');
            setSuccessData(() => ({ status: true, msg: response.message }));
        } catch (err) {
            setLoading(false);
            setErrorMessage(() => 'unexpected_error');
        }
    };
    return (
        <>
            <Header forceHideHamburger />
            {success.status ? (
                <>
                    <div className="access-link-page">
                        <img src={check} />
                        <h2>{t(success.msg)}</h2>
                        <button onClick={() => setSuccessData(() => ({ status: false, msg: '' }))} className="access-link-back">
                            {t('back_to_login')}
                        </button>
                    </div>
                </>
            ) : (
                <div className="login-page">
                    <div className="login-page-title">
                        {t(
                            product === 'fun-box'
                                ? 'are_you_member_funbox'
                                : product === 'entertainu'
                                ? 'are_you_member_entertainu'
                                : product === 'flixvid'
                                ? 'are_you_member_flixvid'
                                : product === 'mixstreamflix'
                                ? 'are_you_member_mixstreamflix'
                                : product === 'sparkstream'
                                ? 'are_you_member_sparkstream'
                                : 'are_you_member'
                        )}
                    </div>
                    <div className="login-page-sub">{t('then_confirm_email_only')}</div>
                    <div className="login-page-input">
                        <input type="email" value={email} placeholder={t('your_email')} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="login-page-button">
                        {loading ? (
                            <button>
                                <FaSpinner />
                            </button>
                        ) : (
                            <button onClick={onClickHandler}>{t('access_link')}</button>
                        )}
                    </div>
                    <Link to="/forgot-password" className="login-page-forgot-pwd">
                        {t('forgot_password')}
                    </Link>
                    {errorMessage && <div className="login-page-error">{t(errorMessage)}</div>}
                    <Footer />
                </div>
            )}
        </>
    );
};

export default LoginWithEmailAndAccessLink;
