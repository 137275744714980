import React from 'react';
import classNames from 'classnames';
import Mastercard from '../../assets/images/mastercard.png';
import Visa from '../../assets/images/visa.png';
import useTranslate from '../../hooks/useTranslate';
import { _isAMBFooterSubdomain, _isFixedFooterSubdomain } from '../../constants/conditionals';
import PrivacyPolicyLink from '../PrivacyPolicyLink/PrivacyPolicyLink';
import TnCLink from '../TnCLink/TnCLink';
import ProtectedLink from '../../utils/ProtectedLink';
import FAQLink from '../FAQLink/FAQLink';
import { sendOnClickE } from '../../analytics/data-collection';
import RefundPolicyLink from '../RefundPolicyLink/RefundPolicyLink';
import { config } from '../../constants/domainConfig';
import { getCopyrightText, getLogo } from '../../utils/helper';

const AMBFooter = () => {
    const t = useTranslate();
    const logo = getLogo();
    const copyrightText = getCopyrightText();
    return (
        <footer className="amb-footer">
            <div className="footer-top">
                <div className="footer-top-left">
                    <img src={logo} alt="" />
                    <ProtectedLink to="/unsubscribe" onClick={() => sendOnClickE({ name: 'help-centre' })}>
                        {t('help_centre')}
                    </ProtectedLink>
                </div>
                <div className="footer-top-right">
                    <div>AMB GLOBAL DIGITAL SOLUTIONS LTD</div>
                    <div>1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus</div>
                    <a href="https://www.ambglobaldigital.com/">www.ambglobaldigital.com</a>
                    <div>
                        {t('copyright_text', {
                            span: () => copyrightText,
                        })}{' '}
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom-left">
                    <FAQLink />
                    <RefundPolicyLink />
                    <PrivacyPolicyLink />
                    <TnCLink />
                </div>
                <div className="footer-bottom-right">
                    <img src={Mastercard} alt="" />
                    <img src={Visa} alt="" />
                </div>
            </div>
        </footer>
    );
};

export default function Footer() {
    const t = useTranslate();
    if (_isAMBFooterSubdomain) return <AMBFooter />;
    else
        return (
            <footer className={classNames('footer', { 'fixed-footer': _isFixedFooterSubdomain })}>{t('copyright_text', { span: () => '2022 © Movio' })}</footer>
        );
}
