import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useTranslate from '../../hooks/useTranslate';
import { MdOutlineGTranslate as GoogleTranslateIcon } from 'react-icons/md';
import { FaChevronLeft as LeftIcon, FaSearch as SearchIcon } from 'react-icons/fa';
import { IoMdClose as CloseIcon } from 'react-icons/io';
// import Logo from '../../assets/logos/logo-movio.svg';
// import LogoEntertainu from '../../assets/logos/entertainu_w.svg';
// import LogoFunbox from '../../assets/logos/funbox.svg';
import useResize from '../../hooks/useResize';
import Sidebar from '../Sidebar/Sidebar';
import Backdrop from '../BackDrop/BackDrop';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';
import { showLangModal } from '../../redux/actions';
import useGetConfig from '../../hooks/useGetPageConfig';
import multiLangDomains from '../../constants/multiLang';
import { config } from '../../constants/domainConfig';
import { getLogo } from '../../utils/helper';

export default function Header({
    headerText,
    searchOpen,
    searchTerm,
    onSearchOpen,
    onSearchClose,
    onSearchChange,
    onSearchClear,
    forceHideHamburger,
    forceShowSearchOnMobile,
    showBackButton,
    showActionButton,
    ActionEl,
    onAction,
    onBackButton,
    style,
    className,
}) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const openModal = () => dispatch(showLangModal(true));
    const { width } = useResize();
    const { rtl, subdomain } = useGetConfig();
    const navigate = useNavigate();
    const handleOpen = () => setOpen(() => true);
    const handleClose = () => setOpen(() => false);
    const showLangButton = Object.keys(multiLangDomains).indexOf(subdomain) >= 0;
    const logo = getLogo();
    return (
        <>
            <header dir={rtl ? 'rtl' : 'ltr'} className={classNames('app-header', { [className]: className ? true : false })} style={{ ...style }}>
                <div className={config.product === 'flixvid' ? 'logoSize' : 'logo'} onClick={() => navigate('/home')}>
                    {!headerText || headerText.length === 0 ? <img src={logo} alt="Movio" /> : <div className="header-text">{headerText}</div>}
                </div>
                {(width > 576 || _isAMBFooterSubdomain) && !forceHideHamburger ? (
                    <div className="hamburger">
                        <Hamburger onClick={handleOpen} />
                    </div>
                ) : showBackButton ? (
                    <button className="back-icon" onClick={onBackButton}>
                        <LeftIcon />
                    </button>
                ) : null}
                {!(searchOpen === undefined) && (
                    <SearchBar
                        showSearchIcon={(width > 576 || _isAMBFooterSubdomain) && !showActionButton}
                        forceShowSearchIcon={forceShowSearchOnMobile}
                        open={searchOpen}
                        term={searchTerm}
                        onOpen={onSearchOpen}
                        onClose={onSearchClose}
                        onChange={onSearchChange}
                        onClear={onSearchClear}
                    />
                )}
                {(width < 576 || forceHideHamburger) && !_isAMBFooterSubdomain && !showActionButton && showLangButton ? (
                    <button className="action-icon" onClick={openModal}>
                        <GoogleTranslateIcon />
                    </button>
                ) : null}
                {showActionButton && !forceShowSearchOnMobile && <ActionButton ActionEl={ActionEl} onClick={onAction} />}
            </header>
            {!forceHideHamburger && <Sidebar open={open} onClose={handleClose} />}
            {open && <Backdrop onClick={handleClose} />}
        </>
    );
}

const Hamburger = ({ onClick }) => (
    <button onClick={onClick}>
        <div />
        <div />
        <div />
    </button>
);

const SearchBar = ({ showSearchIcon, forceShowSearchIcon, open, term, onOpen, onClose, onChange, onClear }) => {
    const t = useTranslate();
    if (open)
        return (
            <div className="searchbar">
                <div className="searchbar-input">
                    <input autoFocus type="text" placeholder={t('search') + '...'} value={term} onChange={onChange} />
                    <span className="searchbar-input__addon-left">
                        <SearchIcon />
                    </span>
                    <span className="searchbar-input__addon-right" onClick={onClear}>
                        <CloseIcon />
                    </span>
                </div>
                <button onClick={onClose}>{t('cancel')}</button>
            </div>
        );
    else
        return forceShowSearchIcon || showSearchIcon ? (
            <button className="search-icon" onClick={onOpen}>
                <SearchIcon />
            </button>
        ) : null;
};

const ActionButton = ({ ActionEl, onClick }) => (
    <button className="action-icon" onClick={onClick}>
        {ActionEl}
    </button>
);
