import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { getLogo } from '../../utils/helper';

export default function PageLoader() {
    const logo = getLogo();
    return (
        <div className="page-loader">
            <img src={logo} alt="" />
            <FaSpinner />
        </div>
    );
}
