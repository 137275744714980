import React from 'react';
import Header from '../../components/Header/Header';
import createDOMPurify from 'dompurify';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import TermsAndConditionsMovioEN from './translations/en/TermsAndConditions.html';
import TermsAndConditionsFunBoxEN from './translations/en/TermsAndConditionsFunBox.html';
import TermsAndConditionsEntertainuEN from './translations/en/TermsAndConditionsEntertainu.html';
import TermsAndConditionsFlixvidEN from './translations/en/TermsAndConditionsFlixvid.html';
import TermsAndConditionsMixstreamflixEN from './translations/en/TermsAndConditionsMixstreamflix.html';
import TermsAndConditionsSparkstreamEN from './translations/en/TermsAndConditionsSparkstream.html';

const sanitize = (html) => {
    if (!window) return '';
    return createDOMPurify(window).sanitize(html);
};

const getTermsAndConditionsTranslated = (lang, subdomain, product) => {
    switch (product) {
        case 'fun-box':
            return TermsAndConditionsFunBoxEN;
        case 'entertainu':
            return TermsAndConditionsEntertainuEN;
        case 'flixvid':
            return TermsAndConditionsFlixvidEN;
        case 'mixstreamflix':
            return TermsAndConditionsMixstreamflixEN;
        case 'sparkstream':
            return TermsAndConditionsSparkstreamEN;
        default:
            return TermsAndConditionsMovioEN;
    }
};

const TermsAndConditions = () => {
    const { lang, subdomain, product } = useGetPageConfig();
    return (
        <>
            <Header forceHideHamburger />
            <div className="static-pages">
                <div dangerouslySetInnerHTML={{ __html: sanitize(getTermsAndConditionsTranslated(lang, subdomain, product)) }} />
            </div>
        </>
    );
};

export default TermsAndConditions;
