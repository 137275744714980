import { config } from './domainConfig';

export const isCreditCardPortal = () => {
    console.log(config);
    if (
        ['moviotv', 'fun-box', 'entertainu', 'flixvid', 'mixstreamflix', 'sparkstream'].indexOf(config.product) >= 0 &&
        ['portal', 'max'].indexOf(config.subdomain) >= 0
    )
        return true;
    else return false;
};
