import { config } from '../constants/domainConfig';
import Logo from '../assets/logos/logo-movio.svg';
import LogoEntertainu from '../assets/logos/entertainu_w.svg';
import LogoFunbox from '../assets/logos/funbox.svg';
import LogoFlixvid from '../assets/logos/FLIXVID_LOGO.svg';
import LogoMixStreamFlix from '../assets/logos/MixStreamFlix_Logo.svg';
import LogoSparkStream from '../assets/logos/SparkStream_Logo.svg';

export const getLogo = () => {
    const logo =
        config.product === 'fun-box'
            ? LogoFunbox
            : config.product === 'entertainu'
            ? LogoEntertainu
            : config.product === 'flixvid'
            ? LogoFlixvid
            : config.product === 'mixstreamflix'
            ? LogoMixStreamFlix
            : config.product === 'sparkstream'
            ? LogoSparkStream
            : Logo;
    return logo;
};

export const getCopyrightText = () => {
    const text =
        config.product === 'fun-box'
            ? '© Funbox'
            : config.product === 'entertainu'
            ? '© EntertainU'
            : config.product === 'flixvid'
            ? '© FlixVid'
            : config.product === 'mixstreamflix'
            ? '© Mixstream Flix'
            : config.product === 'sparkstream'
            ? '© SparkStream'
            : '© Movio';
    return text;
};
